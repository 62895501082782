import React from 'react'
import {connect} from 'react-redux'
import * as Shared from './shared'

/**
 * Player for VOD videos
 * @param user
 * @param hls
 * @param video
 * @param autoplay
 * @param playable
 */
function PlayerVod({user, hls, video, autoplay = false, playable}) {
    return <>
        <Shared.PlayerWithShare
            autoplay={autoplay}
            hls={hls}
            user={user}
            playable={true}
            video={video}
        />
    </>
}

export default connect(state => {
    return {
        video: state.video,
    }
})(PlayerVod)
