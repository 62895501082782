import React, {useState, useEffect} from 'react'
import {
    LoginForm,
    RegisterForm,
    PaymentForm,
    RedeemForm,
    FormTitle,
    FormSubmit,
    ElementText,
    ElementHeading,
    ElementModalClose,
    ElementDiv,
    Message,
} from 'core/components'
import {Theme} from 'core/style'
import {config, share, route, third_party_cookies, in_iframe} from 'core/functions'
import ReplyIcon from 'mdi-react/ReplyIcon'
import FacebookIcon from 'mdi-react/FacebookIcon'
import LinkIcon from 'mdi-react/LinkIcon'
import WhatsappIcon from 'mdi-react/WhatsappIcon'
import {connect} from 'react-redux'
import {retrieve} from 'core/store'
import {Buttons, Ecaptures, Icons} from '@eyecons/storybook'
import {JWPlayer} from 'core/utils'
import {useRouter} from 'next/router'
/**
 * Title for Player Login window
 * @param children
 * @returns {*}
 * @constructor
 */
export const PlayerLoginTitle = ({children}) => <ElementHeading
    text-center
    color-white
    p-8
    type={'h3'}>{children}</ElementHeading>

export const PlayerOverview = ({children}) => <ElementDiv className={'player-login'} style={Theme.PlayerOverview}>
    {children}
</ElementDiv>

function showEmail(video, state) {

    if (!['vod', 'event'].includes(video.type) || !video.channel.ecapture) {
        return false
    }

    return video.channel.ecapture_time === state
}

/**
 * Jw Player
 * @param id
 * @param video
 * @param user
 * @param playable
 * @param autoplay
 * @param setPlaying
 * @param hls
 * @param onError
 */
export const JwPlayer = (
    {
        id,
        video = {},
        user = {},
        playable = false,
        autoplay = false,
        setPlaying,
        hls,
        onError
    }) => {
    const router = useRouter()
    const player_id = id || `jwplayer-player`
    const [email, setEmail] = useState(false)

    function completed() {
        setEmail(showEmail(video, 'after'))

        setPlaying?.(false)
    }

    function initPlayer(video, autoplay) {
        const client = new JWPlayer(user, video)
        client.init(player_id, autoplay, hls)
            .pause(setPlaying)
            .events(completed, router.query)
            .error(onError)
    }

    useEffect(() => {
        if (playable) {
            const ask_email = showEmail(video, 'before')
            setEmail(ask_email)
            initPlayer(video, autoplay && !ask_email)

            return () => {
                JWPlayer.remove()
            }
        }
    }, [video.id, playable, hls])

    return <ElementDiv>
        <div id={player_id}/>
        {
            email && <Ecaptures.PlayerForm
                channel={video.channel}
                onClose={() => setEmail(false)}
            />
        }
    </ElementDiv>
}

export const PlayerShare = ({className, toggleable, size, active, video}) => {
    const [link, setLink] = useState(false)
    const [open, setOpen] = useState(active)

    const share_video = (type) => {
        let shareUrl = route(`${video.route || 'videos'}/show`, {slug: video.slug})

        setOpen(false)
        share(type, shareUrl)
        if (type === 'copy') {
            setLink(true)
            setTimeout(() => setLink(false), 4000)
        }
    }

    return <div className={`share-menu ${className}${open ? ' active' : ''}`}>
        {
            toggleable &&
            <div className="button open" onClick={() => setOpen(!open)}>
                <ReplyIcon size={size} className={'reply'}/>
            </div>
        }
        <div className="button facebook" onClick={() => share_video('facebook')}>
            <FacebookIcon size={size} className={'sharemenu-icon'}/>
        </div>
        <div className="button twitter" onClick={() => share_video('twitter')}>
            <Icons.Icon name={'x-white'} className={'w-8 h-8'}/>
        </div>

        <div className="button whatsapp" onClick={() => share_video('whatsapp')}>
            <WhatsappIcon size={size} className={'sharemenu-icon'}/>
        </div>

        <div className="button link" onClick={() => share_video('copy')}>
            <LinkIcon size={size} className={'sharemenu-icon'}/>
        </div>
        <span className={`deeplink-copy${link ? ' show' : ''}`}>
            Link gekopieerd!
        </span>
    </div>
}

export const PlayerMessage = ({title, message, html, style}) => {
    return <>
        {title && <FormTitle>{title}</FormTitle>}
        {(message || html) && <Message mb-2 html={html} style={style}>{message}</Message>}
    </>
}

export const PlayerModal = ({children, onClose}) => {
    useEffect(() => {
        const element = document.getElementById('video-player')
        element.classList.add('video-player-modal')

        return () => element.classList.remove('video-player-modal')
    }, [])
    return <ElementDiv
        className={`player-form-overview${in_iframe() ? '' : ' player-form-overview-site'}`}
        style={[Theme.PlayerOverViewForm]}>
        <ElementDiv style={Theme.PlayerLogin} className={'player-form'}>
            {onClose && <ElementModalClose size={30} onClose={() => onClose(false)}/>}
            {children}
        </ElementDiv>
    </ElementDiv>
}

const PlayerRegister = ({setPage, video}) => {
    return <RegisterForm
        video={video}
        setPage={setPage}
        login={{
            type: 'button',
            onPress: () => setPage('login'),
        }}/>
}

const PlayerLoginUser = ({setPage, setUser}) => {
    return <LoginForm
        setUser={setUser}
        stay-on-page
        register={{
            type: 'button',
            onPress: () => setPage('register'),
        }}/>
}

export const PlayerTooManySessions = () => {
    return <PlayerOverview>
        <PlayerLoginTitle>Je kan op maximaal 3 devices tegelijk kijken, log uit op een van de je andere devices.</PlayerLoginTitle>
        <FormSubmit onPress={() => location.reload()}>Opnieuw</FormSubmit>
    </PlayerOverview>
}

export const PlayerLoginWindow = ({video, setUser, current_page = 'call-to-action', onClose}) => {
    const [page, setPage] = useState(current_page)

    useEffect(() => {
        third_party_cookies().then((response) => {
            if (!response) {
                setPage('third-party-cookies')
            }
        })
    }, [])

    if (page === 'call-to-action') {
        return <PlayerOverview>
            <PlayerLoginTitle>{video.type === 'live' ? 'Nu live kijken' : 'Neem een abonnement af om deze video te bekijken'}</PlayerLoginTitle>
            <FormSubmit onPress={() => setPage('login')}>Registreren / Inloggen</FormSubmit>
        </PlayerOverview>
    }

    if (page === 'third-party-cookies') {
        return <PlayerModal>
            <PlayerMessage
                html
                title={'Oeps, Cookies geblokkeerd!'}
                message={`Pas je instellingen aan voor de beste ervaring. Hulp nodig? <a href="https://eyecons.zendesk.com/hc/nl/articles/4406915118353-Cookies-van-externe-aanbieders-worden-geblokkeerd-op-mijn-device" target="_blank"><strong>Klik hier</strong></a>! Of log telkens opnieuw in voor toegang, of kijk via <a href="${config('app.url')}/${video.route || 'videos'}/${video.slug}" target="_blank"><strong>Eyecons.com</strong></a>`}
            />
            <FormSubmit onPress={() => setPage('login')}>Inloggen</FormSubmit>
        </PlayerModal>
    }

    return <PlayerModal onClose={onClose ? onClose : () => setPage('call-to-action')}>
        {page === 'register' && <PlayerRegister setUser={setUser} setPage={setPage} video={video}/>}
        {page === 'login' && <PlayerLoginUser setUser={setUser} setPage={setPage} video={video}/>}
    </PlayerModal>
}

export const PlayerNoLoginWindow = ({video, query}) => <PlayerModal>
    <ElementHeading type={'h4'} text-center>Live Kijken</ElementHeading>
    <Message
        mb-2
        html
        children={`Door de <a href="/terms-and-conditions" target="_blank">algemene voorwaarden</a> van Eyecons te accepteren word je met ${query.email} geregistreerd bij Eyecons en kun je deze video bekijken.<br/>`}
        style={{textAlign: 'left', fontSize: '0.75rem'}}/>
    <RegisterForm title={false} mode={'nologin'} video={video}/>
</PlayerModal>

let timer = null

/**
 * Starts a timer that checks for the video status and changes the state.
 * @param playable
 * @param hls
 * @param user
 * @param video_status
 * @param retrieve
 * @param is_admin
 */
export const PlayerWithBroadcasting = connect(state => {
    return {
        video: state.video,
    }
}, dispatch => {
    return {
        retrieve: (endpoint, params) => {
            return dispatch(retrieve(endpoint, params))
        },
    }
})(({playable, hls, user, video, video_params, retrieve, autoplay}) => {
    const [error, setError] = useState({})
    let active = video.status === 'published' && !video.hidden && error.code !== 230001

    useEffect(() => {
        if (!user) {
            return;
        }
        if (timer) {
            clearInterval(timer)
        }

        const isError = error.code === 230001
        const seconds = (isError || active) ? 90000 : 60000

        timer = setInterval(() => {
            retrieve('video', {...video_params, viewer_id: user.id}).then(() => setError({}))
        }, seconds)

        return () => clearInterval(timer)
    }, [user, video.status, error.code])


    return <>
        {
            !active && video.type === 'live' && <PlayerOverview>
                <PlayerLoginTitle>
                    Livestream is nog niet gestart of (tijdelijk) beëindigd. Even geduld a.u.b.
                </PlayerLoginTitle>
            </PlayerOverview>
        }

        <PlayerWithShare
            autoplay={autoplay}
            onError={setError}
            playable={playable && active}
            user={user}
            hls={hls}
            video={video}
        />
    </>
})

/**
 * Player with Share buttons.
 *
 * @param playable
 * @param video
 * @param user
 * @param setPlaying
 * @param autoplay
 * @param hls
 * @param onError
 */
export const PlayerWithShare = ({playable, video, user, setPlaying, autoplay = false, hls, onError}) => {
    if (!playable) {
        return <ElementDiv style={[
            Theme.PlayerThumbnail,
            { backgroundImage: `url(${video.thumbnail_large})` }
        ]} />
    }

    return <>
        <JwPlayer
            playable={playable}
            autoplay={autoplay}
            video={video}
            user={user}
            setPlaying={setPlaying}
            hls={hls}
            onError={onError}
        />

        {
            playable && <PlayerShare
                className={'player-share-menu controlgroup'}
                size={24}
                toggleable
                active={false}
                video={video}/>
        }
    </>
}

export const PlayerPaymentWindow = ({video, user, is_embed = false}) => {
    const [page, setPage] = useState('call-to-action')

    if (page === 'call-to-action') {
        return <PlayerOverview>
            <div>
                <ElementText style={Theme.PlayerOption}>Kies een van de volgende opties om te kijken:</ElementText>
                <div className={'player-buttons'}>
                    {
                        video.price.enabled && <Buttons.Button type={'primary'} onClick={() => setPage('pay')}>
                            Betaal eenmalig {video.price.amount.price('EUR', 'nl-NL')}
                        </Buttons.Button>
                    }

                    {
                        video.has_public_plans &&
                        <Buttons.Link
                            type={'primary'}
                            to={`/subscriptions?${video.has_event ? 'event_id' : 'video_id'}=${video.id}`}
                            target={is_embed ? '_blank' : ''}
                        >
                            Kies een abonnement
                        </Buttons.Link>}
                </div>
            </div>
        </PlayerOverview>
    }

    return <PlayerModal onClose={() => setPage('call-to-action')}>
        <PaymentForm user={user} video={video} simple/>
    </PlayerModal>
}

export const PlayerVoucherWindow = ({redeem, submitRedeem}) => <PlayerOverview>
    <RedeemForm redeem={redeem} submitRedeem={submitRedeem}/>
</PlayerOverview>
