import React from 'react'
import {
    ElementRow,
    ElementColumn,
    ElementDiv,
    ElementHeading,
    ElementText,
    ElementIcon,
    ElementActivity,
    ElementInfo,
    ElementImage,
    ElementBadge,
    Card,
    CardThumbnail,
    CardBody,
    Ad,
} from 'core/components'
import {RawTheme, Theme} from 'core/style'
import {Link} from 'core/router'
import {connect} from 'react-redux'
import {retrieveMore} from 'core/store'
import {Likes} from '@eyecons/storybook'

export {default as VideosPlans} from './Plans'

export const VideoMatchResultChannel = ({channel, right}) => {
    const Logo = () => <ElementImage
        alt={'logo'}
        disable_cdn
        source={channel.club.logo}
        type={'fluid'}
        style={{...RawTheme.ChannelLogo, ...RawTheme[`ChannelLogo${right ? 'Right' : 'Left'}`]}}/>

    const props = right ? {'flex-row': true, 'justify-content-end': true} : {}

    return <ElementColumn {...props} col>
        <Link to={'channels/show'} params={{id: channel.club.name}} className={'channel-link'} no-container>
            <ElementDiv flex-row align-items-center>
                {!right && <Logo/>}
                <ElementHeading
                    type={'h4'}
                    className={'channel-name'}
                    style={RawTheme.ChannelName}
                    display-none>
                    {channel.club.label}
                </ElementHeading>

                {
                    channel.club.short &&
                    <ElementText className={'channel-shortname'} style={Theme.ChannelShortTitle}>
                        {channel.club.short}
                    </ElementText>
                }
                {right && <Logo/>}
            </ElementDiv>
        </Link>
    </ElementColumn>
}

export const VideoMatchResult = ({video}) => {
    const isHome = video.match_location !== 'away'
    const home_score = isHome ? video.team_score : video.opponent_score
    const away_score = isHome ? video.opponent_score : video.team_score

    return <ElementDiv className={'video-match-result'} block-level-margin>
        <ElementRow align-items-center>
            <VideoMatchResultChannel channel={isHome ? video.team : video.opponent}/>

            <ElementColumn col>
                <ElementHeading type={'h3'} style={RawTheme.VideoMatchResult} text-center>
                    {home_score === null ? '' : home_score}-{away_score === null ? '' : away_score}
                </ElementHeading>
            </ElementColumn>

            <VideoMatchResultChannel channel={isHome ? video.opponent : video.team} right/>
        </ElementRow>
    </ElementDiv>
}

export const VideoCard = ({video = {}, type, style, item, onPress, details}) => {
    return <Card
        type={type}
        style={style}
        to={`${video.route || 'videos'}/show`}
        onPress={onPress} params={{slug: video.slug}}>
        <div ref={item}>
            <CardThumbnail thumbnail={video.thumbnail}>
                {
                    video.type !== 'live' && video.length?.seconds > 0 &&
                    <ElementText className={'video-length'} style={Theme.VideoLength} small>
                        {video.length.seconds.time()}
                    </ElementText>
                }
            </CardThumbnail>

            <CardBody>
                <ElementHeading style={{fontWeight: 'bold', fontSize: '16px'}} type={'h4'}
                                className={'elements-line-clamp'}>{video.title}</ElementHeading>
                {details &&
                <>
                    <ElementText small>{video.channel.label}</ElementText>
                    <ElementText small>
                        {video.views ? `${video.views},` : ''}
                        {typeof video.created_at === 'object' && video.created_at !== null ? video.created_at.label : video.created_at}
                    </ElementText>
                </>
                }
                {
                    ['event', 'vod'].includes(video.type) &&
                    <ElementText className={'card-competition'} style={Theme.VideoCompetition}>
                        <ElementIcon type={video.sport.name} style={Theme.VideoSportIcon} color-blues-400/>
                        {video.channel.label}
                    </ElementText>
                }
            </CardBody>
        </div>
    </Card>
}

function getPath(pathname, query) {
    if (query.category && pathname.indexOf('/team/category') === -1) {
        return pathname + '/team/category'
    }

    if (query.team && pathname.indexOf('/show/team') === -1) {
        return pathname + '/team'
    }

    return pathname
}

function totalViewsText(totalViews) {
    return `${totalViews ?? 0} ${totalViews === 1 ? 'weergave' : 'weergaven'}`
}

export const Videos = connect((state, props) => {
    const {filters = {}, params = {}} = props
    const route = props.route || 'videos'
    let meta = state[route].meta || {}

    const pagination = filters && filters.page > meta.current_page ? {
        current_page: Number(filters.page),
        from: Number(filters.page),
        last_page: meta.total / params.paginate,
        to: params.paginate,
        per_page: params.paginate,
    } : meta

    return {
        loading: state.loading[route],
        pagination: pagination,
        route: route,
    }
}, dispatch => {
    return {
        retrieveMore: (endpoint, params, token) => {
            dispatch(retrieveMore(endpoint, params, token))
        },
    }
})((props) => {
    const {videos, pagination, route, retrieveMore, loading, params, token, filters = {}} = props
    const {current_page, last_page} = pagination
    const is_last_page = current_page >= last_page

    const loadMoreVideos = () => {
        if (!pagination.current_page) {
            return
        }

        delete params.category
        delete params.team

        const page = pagination.current_page + 1

        if (filters.team && filters.team !== 'alle-teams') {
            params.team = filters.team
        }

        if (filters.category) {
            params.category = filters.category
        }

        retrieveMore(route, {...params, page}, token)
    }

    return <>
        <ElementRow>
            {
                videos.map((video, index) => <ElementColumn key={`${index}-${video.id}`} col={12} md={6} lg={4} xl={3}>
                        <VideoCard type={'responsive-item'} video={video}/>
                    </ElementColumn>,
                )
            }
        </ElementRow>
        {!is_last_page && props['load-more'] && <ElementActivity loading={loading} onEnter={loadMoreVideos}/>}
    </>
})

// TODO load ad for livestreams and only on livestreams page
export const VideoArchive = (props) => <>
    {props.type === 'live' && <Ad className={'leaderboard-homepage'} type={'leaderboard-billboard'}/>}
    {
        !props.loading && <ElementDiv flex-row justify-content-center align-items-center block-level-margin>
            {props.icon && props.icon()}
            <ElementHeading type={'h2'} style={RawTheme.VideosTitle}>{props.title}</ElementHeading>
        </ElementDiv>
    }
    <Videos {...props}/>
</>

export const VideoLiveCard = ({livestream}) => {
    return (
        <ElementColumn style={Theme.VideoLiveCard} col={12} md={6}>
            <Link to={`${livestream.route}/show`} params={{slug: livestream.slug}} no-container>
                <CardThumbnail thumbnail={livestream.thumbnail}>
                    <ElementDiv className={'video-live-title'} style={Theme.VideoLiveInfo}>
                        <ElementDiv flex-row align-items-center>
                            <ElementBadge style={Theme.VideoLiveBadge}>Live</ElementBadge>
                            {/*<ElementIcon type={video.sport.name} color-white/>*/}
                        </ElementDiv>
                        <ElementHeading style={RawTheme.VideoLiveTitle} type={'h3'}>{livestream.title}</ElementHeading>
                    </ElementDiv>
                </CardThumbnail>
            </Link>
        </ElementColumn>
    )
}

export const VideoDetails = ({video, user, user_loading}) => {
    return <ElementInfo>
        {(video.team && video.opponent) && <VideoMatchResult video={video}/>}

        <ElementHeading type={'h1'}>{video.title}</ElementHeading>

        <div className={'mb-4 flex justify-between'}>
            {
                !user_loading && Boolean(video.external_id) && video.status === 'published' && <Likes.Create user={user} video={video}/>
            }

            {video.type !== 'live' &&
                <ElementText className={'mb-4'}>{totalViewsText(video.views)} • {video.date.label}</ElementText>
            }
        </div>

        <ElementText
            className={'video-description'}
            dangerouslySetInnerHTML={{__html: video.description}}
        />
    </ElementInfo>
}

export const VideoRecommended = ({video, clubVideos, competitionVideos, details, md, lg}) => <ElementColumn
    md={md}
    lg={lg}
    className={'video-recommended pl-md-3 pl-xl-4'}
>

    <Ad className={'video-recommended-ads'} type={'rectangle-halfpage'}/>

    {
        Boolean(clubVideos.length) &&
        <ElementInfo>
            <ElementHeading
                type={'h3'}
                style={RawTheme.VideoRecommendationsTitle}>Wedstrijdvideo’s</ElementHeading>
        </ElementInfo>
    }

    {
        clubVideos.map((recommended, index) => {
            recommended.sport = video.sport
            return <VideoCard key={index} video={recommended} details={true} type={'mobile-item'}/>
        })
    }

    {
        Boolean(competitionVideos.length) &&
        <ElementInfo>
            <ElementHeading
                type={'h3'}
                style={RawTheme.VideoRecommendationsTitle}>Aanbevolen voor jou</ElementHeading>
        </ElementInfo>
    }

    {
        competitionVideos.map((recommended, index) => {
            recommended.sport = video.sport
            return <VideoCard key={index} details={details} video={recommended} type={'mobile-item'}/>
        })
    }

</ElementColumn>
